<template>
  <div class="container">
    <!-- <van-nav-bar
      :title="title"
      :left-text="!isSystem ? '注册' : ''"
      :left-arrow="!isSystem"
      @click-left="onClickLeft"
      class="manager-nav"
    />-->
    <div class="content">
      <div class="content-text">
        <img src="../../assets/register.png" alt />
        您目前无法登录系统
        <br />请先绑定员工账号进行使用
      </div>
      <div class="content-form">
        <van-form @submit="onSubmit" :show-error="false" label-width="56px">
          <van-field
            v-model="formData.username"
            label="姓名"
            placeholder="请输入姓名"
            required
            :rules="formDataRules.username"
            maxlength="32"
            clearable
          />
          <van-field
            readonly
            clickable
            name="picker"
            :value="formData.store"
            label="门店"
            placeholder="请选择门店"
            @click="showStore = true"
            required
            :rules="formDataRules.store"
            clearable
          />
          <van-field
            readonly
            clickable
            name="picker"
            :value="formData.position"
            label="职位"
            placeholder="请选择职位"
            @click="showPosition = true"
            required
            :rules="formDataRules.store"
            clearable
          />
          <van-field
            v-model="formData.phone"
            label="手机号"
            type="digit"
            placeholder="请输入手机号"
            required
            :rules="formDataRules.phone"
            maxlength="11"
            clearable
          />
          <van-field
            v-model="formData.imageCode"
            center
            clearable
            label="图片验证"
            placeholder="请输入验证码"
            required
            :rules="formDataRules.imageCode"
            maxlength="7"
          >
            <template #button>
              <img
                :src="formData.captcha_image_content"
                alt=""
                @click="getPictrueCode"
            /></template>
          </van-field>
          <van-field
            v-model="formData.code"
            center
            clearable
            label="验证码"
            placeholder="请输入短信验证码"
            required
            :rules="formDataRules.code"
            type="digit"
            maxlength="4"
          >
            <template #button>
              <van-button
                size="small"
                type="primary"
                :id="
                  isSendCode === true ? 'code-primary-active' : 'code-primary'
                "
                :loading="loadingCode"
                loading-text="获取验证码"
                @click.prevent="sendCode"
                :disabled="isSendCode"
                >{{
                  show === true ? "获取验证码" : count + "秒后重试"
                }}</van-button
              >
            </template>
          </van-field>
          <div style="margin: 16px; margin-top: 40px">
            <van-button
              block
              type="info"
              native-type="submit"
              :loading="loading"
              loading-text="提交"
              >提交</van-button
            >
          </div>
        </van-form>

        <!-- 门店选择 -->
        <van-popup v-model="showStore" position="bottom">
          <van-picker
            show-toolbar
            :columns="storeList"
            @confirm="onConfirm"
            @cancel="showStore = false"
          />
        </van-popup>
        <!-- 职位选择 -->
        <van-popup v-model="showPosition" position="bottom">
          <van-picker
            show-toolbar
            :columns="positionList"
            @confirm="onConfirmPosition"
            @cancel="showPosition = false"
          />
        </van-popup>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getSelectOptionsList,
  getSendCode,
  register,
  getPictrueCode,
} from "@/api/api";
export default {
  data() {
    return {
      // 按钮加载
      loading: false,
      // 发送验证码加载
      loadingCode: false,
      title: "",
      isSystem: true,
      // 表单信息
      formData: {
        username: "",
        store: "",
        position: "",
        // 职位索引
        positionIndex: "",
        phone: "",
        // 发送短信时返回的key
        verifykey: "",
        code: "",
        imageCode: "",
        captchacode: "",
        // 验证码图片
        captcha_image_content: "",
      },
      // 表单验证
      formDataRules: {
        username: [{ required: true, message: "请输入姓名" }],
        store: [{ required: true, message: "请选择门店" }],
        position: [{ required: true, message: "请选择职位" }],
        phone: [
          { required: true, message: "请输入电话" },
          { pattern: /^1[3456789]\d{9}$/, message: "手机号码格式错误！" },
        ],
        code: [{ required: true, message: "请输入短信验证码" }],
        imageCode: [{ required: true, message: "请输入验证码" }],
      },
      // 门店列表
      storeList: [],
      // 职位列表
      positionList: ["院长", "护工", "护士", "销售"],
      // 门店的列表显示与隐藏
      showStore: false,
      // 职位的列表显示与隐藏
      showPosition: false,
      // 判断验证码是否发送
      isSendCode: false,
      // 发送验证码后
      count: "",
      show: true,
      timer: null,
    };
  },
  created() {
    this.title = this.$route.meta.name;
    if (this.$route.path !== "/manager-system") {
      this.isSystem = false;
    } else {
      this.isSystem = true;
    }
    // 门店获取及职位获取
    this.getStoreList();
    // 图片验证码
    this.getPictrueCode();
  },
  methods: {
    // 返回
    onClickLeft() {
      if (this.isSystem) return;
      this.$router.go(-1);
    },
    // 门店获取及职位获取
    getStoreList() {
      getSelectOptionsList().then((res) => {
        if (res.code === 200) {
          res.data.hospitals.map((item) => {
            this.storeList.push(item.name);
          });
          this.positionList = res.data.positions;
        }
      });
    },

    // 获取图片验证码
    getPictrueCode() {
      getPictrueCode().then((res) => {
        if (res.code === 200) {
          this.formData.captcha_image_content = res.data.captcha_image_content;
          this.formData.captchacode = res.data.captcha_key;
        }
      });
    },
    // 注册
    onSubmit() {
      const wxid = window.sessionStorage.getItem("wxid");
      this.loading = true;
      register({
        type: this.positionIndex,
        wxid: wxid,
        name: this.formData.username,
        mobile: this.formData.phone,
        position: this.positionIndex,
        hospitals: 1,
        verifykey: this.formData.verifykey,
        verifycode: this.formData.code,
        captchakey: this.formData.captchacode,
        captchacode: this.formData.imageCode,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$toast(res.data);
            this.$router.push({ path: "/registerSuccess" });
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 门店选择
    onConfirm(value) {
      this.formData.store = value;
      this.showStore = false;
    },
    // 职位选择
    onConfirmPosition(value, index) {
      if (index === 0) {
        this.$toast("请选择职位");
      } else if (index === 1) {
        this.formData.position = value;
        this.positionIndex = 1;
        this.showPosition = false;
      } else if (index === 2) {
        this.formData.position = value;
        this.positionIndex = 2;
        this.showPosition = false;
      }
    },
    // 发送验证码
    getSendCode() {
      this.loadingCode = true;
      getSendCode({
        mobile: this.formData.phone,
        captchakey: this.formData.captchacode,
        captchacode: this.formData.imageCode,
      })
        .then((res) => {
          if (res.code === 201) {
            this.formData.verifykey = res.data.key;
            this.loadingCode = false;
            this.isSendCode = true;
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.show = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  this.isSendCode = false;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            this.$toast(res.data);
            this.getPictrueCode();
            this.loadingCode = false;
          }
        })
        .catch((error) => {
          this.loadingCode = false;
        });
    },
    // 发送验证码
    sendCode() {
      if (this.formData.imageCode === "") {
        this.$toast("请输入图片验证码");
      } else if (this.formData.phone === "") {
        this.$toast("请输入手机号");
      } else {
        // 发送验证码
        this.getSendCode();
      }
    },
  },
  watch: {
    $route(val) {
      this.title = val.meta.name;
      if (val.path !== "/manager-system") {
        this.isSystem = false;
      } else {
        this.isSystem = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.van-nav-bar .van-icon,
/deep/.van-nav-bar__text {
  color: #f3973b;
}
.manager-nav {
  position: fixed;
  top: 0;
  width: 100%;
}
.container {
  background-color: #fff;
  padding-bottom: 22px;
  min-height: 100%;
  box-sizing: border-box;
}
.content {
  padding: 0 22px;
  .content-text {
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ff6b54;
    line-height: 24px;
    text-align: center;
    margin-top: 23px;
    margin-bottom: 25px;
    img {
      width: 12px;
      height: 12px;
    }
  }
  .content-form {
    min-height: 368px;
    background: #ffffff;
    box-shadow: 0px 6px 28px 1px rgba(40, 40, 40, 0.15);
    border-radius: 10px;
    padding: 6px;
    /deep/.van-cell {
      padding: 0.36667rem 0.42667rem;
    }
    /deep/.van-field__control {
      font-size: 14px;
    }
    /deep/.van-field__label,
    /deep/.van-field__body {
      span {
        font-size: 14px;
      }
    }
    /deep/.van-button--info,
    /deep/.van-button--primary {
      background-color: #f3973b;
      border: #f3973b;
    }
    #code-primary {
      background-color: #f3973b;
      border: #f3973b;
    }
    #code-primary-active {
      background-color: #999;
      border: #999;
    }
  }
}
</style>